import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, redirect, useNavigate } from "react-router-dom";

function AdminPage() {
    const password = "Oleg@2Kastyuk";
    const passwordInput = useRef(null);
    const [isPasswordCleared, setIsPasswordCleared] = useState(false);
    const [currentProjects, setCurrentProjects] = useState({});
    const formRef = useRef(null);
    const nav = useNavigate();
    useEffect(() => {
        fetch("https://oleg-lovsky-default-rtdb.firebaseio.com/projects.json")
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setCurrentProjects(data);
            });
    }, []);
    function handlePasswordSubmit() {
        //@ts-ignore
        if (passwordInput.current.value === password) {
            setIsPasswordCleared(true);
        }
    }
    function handleFormSubmit() {
        //@ts-ignore
        let inputs: HTMLInputElement[] = document.getElementsByClassName("admin-pic-input");
        let duplicateCheck = true;

        //checking for duplicate numbers
        let usedNumbers: number[] = [];
        //@ts-ignore
        let usedCombos: [[number, string]] = [];
        for (let i = 0; i < inputs.length; i++) {
            //@ts-ignore
            let value: number = inputs[i].value;
            let name: string = inputs[i].name;

            if (usedNumbers.indexOf(value) !== -1) {
                duplicateCheck = false;
                break;
            } else {
                usedNumbers.push(value);
                usedCombos.push([value, name]);
            }
        }
        if (duplicateCheck === false) {
            alert("Oleg you had duplicate numbers");
            return;
        }

        //sorting new choices
        //@ts-ignore
        let sorted = usedCombos.toSorted((elmA, elmB) => {
            return elmA[0] - elmB[0];
        });
        //filling new object
        let newProjectOrder = {};
        for (let i = 0; i < sorted.length; i++) {
            let currentName = sorted[i][1];
            let currentData;
            for (let j = 0; j < Object.entries(currentProjects).length; j++) {
                if (Object.entries(currentProjects)[j][1]) {
                    //@ts-ignore
                    if (Object.entries(currentProjects)[j][1].name === currentName) {
                        currentData = Object.entries(currentProjects)[j][1];
                    }
                }
            }
            //@ts-ignore
            newProjectOrder[i] = currentData;
        }
        axios.put("https://oleg-lovsky-default-rtdb.firebaseio.com/projects.json", newProjectOrder);
        alert("Great job my brother :)");
        nav("/");
        return;
    }
    if (!isPasswordCleared) {
        return (
            <div>
                <div>Admin Page</div>
                <input type="text" placeholder="insert password" ref={passwordInput} />
                <button onClick={handlePasswordSubmit}>submit</button>
            </div>
        );
    } else {
        return (
            <div className="admin-projects-wrapper">
                <form ref={formRef}>
                    {Object.entries(currentProjects).map((proj) => {
                        if (proj[1] === null) {
                            return;
                        } else {
                            return (
                                <div className="admin-pic-wrapper my-2">
                                    <input
                                        className="admin-pic-input"
                                        type="number"
                                        defaultValue={proj[0]}
                                        name={
                                            //@ts-ignore
                                            proj[1].name
                                        }
                                    />

                                    <img
                                        src={
                                            //@ts-ignore
                                            proj[1].pics[1]
                                        }
                                        className="admin-pic"
                                    />
                                </div>
                            );
                        }
                    })}
                </form>
                <button className="btn btn-success" onClick={handleFormSubmit}>
                    Submit
                </button>
            </div>
        );
    }
}

export default AdminPage;
